import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './md.css'

const MarkdownViewer = ({ file }) => {
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await axios.get(file);
        setContent(response.data);
      } catch (error) {
        console.error('Error fetching the Markdown file:', error);
      }
    };

    fetchMarkdown();
  }, [file]);
  
  return (
    <div>
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  );
};

export default MarkdownViewer;
